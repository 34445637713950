//$font_path: '.././assets/fonts';
$font_path: '/assets/fonts';


/*
https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-weight
Common weight name mapping
The numerical values 100 to 900 roughly correspond to the following common weight names:
Value	Common weight name
100	Thin (Hairline)
200	Extra Light (Ultra Light)
300	Light
400	Normal
500	Medium
600	Semi Bold (Demi Bold)
700	Bold
800	Extra Bold (Ultra Bold)
900	Black (Heavy)

 */
//=========== WEB-FONTS =============//
//@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Quicksand:wght@500;600;700&display=swap');
@import '../fonts/Roboto/style.css';
@import '../vendors/fontawesome/css/all.min.css';
//@font-face {
//    font-family: "Icons";
//    src: url("../fonts/icons/Icons.eot?6e40br");
//    src:
//	url("../fonts/icons/Icons.eot?6e40br#iefix") format("embedded-opentype"),
//	url("../fonts/icons/Icons.ttf?6e40br") format("truetype"),
//	url("../fonts/icons/Icons.woff?6e40br") format("woff"),
//	url("../fonts/icons/Icons.svg?6e40br#Icons") format("svg");
//    font-weight: normal;
//    font-style: normal;
//}