@import './bootstrap_core';
// https://jonsuh.com/hamburgers/#customization
$hamburger-padding-x           : 15px;
$hamburger-padding-y           : 15px;
$hamburger-layer-width         : 30px;
$hamburger-layer-height        : 2px;
$hamburger-layer-spacing       : 8px;
$hamburger-layer-color         : #ffffff;
$hamburger-active-layer-color  : #ffffff;
$hamburger-layer-border-radius : 0px;
$hamburger-hover-opacity       : 0.7;
@import '../../../node_modules/hamburgers/_sass/hamburgers/hamburgers';