//  Bootstrap must be set up in a specific order so that your custom overrides work correctly.

//
//  1. First, import functions (so you can manipulate colors, SVGs, calc, etc):
//
@import "../../../node_modules/bootstrap/scss/functions";

//
//  2. Next, include any overrides of default variable values (optional). For example:
//

/*
$custom-color: #df711b;
$custom-theme-colors: (
   "custom": $custom-color
);
*/

//
//  3. Then, import the default Bootstrap variables:
//
@import "../../../node_modules/bootstrap/scss/variables";
// 5.3.1 @import "../../../node_modules/bootstrap/scss/variables-dark";

//
//  4. Then, declare any Sass map overrides (optional). For example:
//

/*
$theme-colors: map-merge($theme-colors, $custom-theme-colors);
*/

//
//  5. Include the other required Bootstrap stylesheets in this order:
//
// 5.3.1 @import "../../../node_modules/bootstrap/scss/maps";
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/utilities";
// 5.3.1 @import "../../../node_modules/bootstrap/scss/utilities/api";

// fix for sass build
@mixin row-cols($count) {
   > * {
     flex: 0 0 auto;
     width: divide(100, $count);
   }
 }
 
//
//  6. Include the optional Bootstrap components that you need. Disable those you don't
//     to reduce the file size of your final CSS. (Or, leave them all enabled and turn on
//     PurgeCSS in CodeKit):
//
@import "../../../node_modules/bootstrap/scss/root";
@import "../../../node_modules/bootstrap/scss/reboot";
@import "../../../node_modules/bootstrap/scss/type";
@import "../../../node_modules/bootstrap/scss/images";
// 5.3.1 @import "../../../node_modules/bootstrap/scss/containers";
@import "../../../node_modules/bootstrap/scss/grid";
@import "../../../node_modules/bootstrap/scss/tables";
@import "../../../node_modules/bootstrap/scss/forms";
@import "../../../node_modules/bootstrap/scss/buttons";
@import "../../../node_modules/bootstrap/scss/transitions";
@import "../../../node_modules/bootstrap/scss/dropdown";
// @import "../../../node_modules/bootstrap/scss/button-group";
@import "../../../node_modules/bootstrap/scss/nav";
@import "../../../node_modules/bootstrap/scss/navbar";
// @import "../../../node_modules/bootstrap/scss/card";
// @import "../../../node_modules/bootstrap/scss/accordion";

/*
@import "../../../node_modules/bootstrap/scss/breadcrumb";
@import "../../../node_modules/bootstrap/scss/pagination";
*/

// @import "../../../node_modules/bootstrap/scss/badge";
@import "../../../node_modules/bootstrap/scss/alert";
// @import "../../../node_modules/bootstrap/scss/progress";
@import "../../../node_modules/bootstrap/scss/list-group";
@import "../../../node_modules/bootstrap/scss/close";
@import "../../../node_modules/bootstrap/scss/toasts";
@import "../../../node_modules/bootstrap/scss/modal";
// @import "../../../node_modules/bootstrap/scss/tooltip";
// @import "../../../node_modules/bootstrap/scss/popover";
// @import "../../../node_modules/bootstrap/scss/carousel";
// @import "../../../node_modules/bootstrap/scss/spinners";
// @import "../../../node_modules/bootstrap/scss/offcanvas";
// 5.3.1 @import "../../../node_modules/bootstrap/scss/placeholders";
// 5.3.1 @import "../../../node_modules/bootstrap/scss/helpers";


// 7. Finally, add your custom Sass below, either directly or via imports:

//@import "main.scss"     // Styles for the page. You can delete this file and import statement.
